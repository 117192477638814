/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


$.fn.equalHeight = function() {
  if($(window).width() > 768){
    var maxHeight = 0;
    return this.each(function(index, box) {
        var boxHeight = $(box).height();
        maxHeight = Math.max(maxHeight, boxHeight);
    }).height(maxHeight);
  }
};


$('body').imagesLoaded( function() {
    $('.content-info .inner').equalHeight();
    $('#downloadlist .well').equalHeight();
});


$(window).resize(function(){
$('body').imagesLoaded( function() {
    $('.content-info .inner').css('height','auto');
    $('.content-info .inner').equalHeight();
    $('#downloadlist .well').css('height','auto');
    $('#downloadlist .well').equalHeight();
});
});


$('.slider-home').slick({
  nextArrow: '<span class="slider-arrow slider-arrow-right"><span class="glyphicon glyphicon-menu-right"></span></span>',
  prevArrow: '<span class="slider-arrow slider-arrow-left"><span class="glyphicon glyphicon-menu-left"></span></span>',
  autoplay: true,
  autoplaySpeed: 6000

});

$( ".sidebar .special-offers-inner" ).wrapAll( "<div class='slider-default slider-arrows-appear'></div>" );

function dsResize() {
    $('.slider-default').each(function(){  
        var dsHighestBox = 0;

        $(this).find('.slick-slide').height('auto');

        $(this).find('.slick-slide').each(function(){
            if($(this).height() > dsHighestBox){  
                dsHighestBox = $(this).height();  
            }
        });

        $(this).find('.slick-slide').height(dsHighestBox);

    });
}

$('.slider-default').on('init', function(){
  $('body').imagesLoaded( function() {
    dsResize();

$(window).resize(function() {
  dsResize();
});

  });
});

$('.slider-default').slick({
  nextArrow: '<span class="slider-arrow slider-arrow-right"><span class="glyphicon glyphicon-menu-right"></span></span>',
  prevArrow: '<span class="slider-arrow slider-arrow-left"><span class="glyphicon glyphicon-menu-left"></span></span>',
  autoplay: true,
  speed: 400,
  autoplaySpeed: 8000

});







/*
dsResize();

$(window).resize(function() {
  dsResize();
});
/*



/* Here's the actual code. */


if($.cookie('remember_country_new')) {

    $('.country-list option[value="' + $.cookie('remember_country_new') + '"]').attr('selected', 'selected');

} else {

  $('#myModal').modal({show: true, backdrop: 'static'});

}

$('#myModal .btn').click(function() {

  if($("#cookieCheck").prop('checked') === true){

    $.cookie('remember_country_new', $('.country-list option:selected').val(), { expires: 365, path: '/', domain: '.zodiac.nl'});
    location.reload();

  } else {
    
    $.cookie('remember_country_new', $('.country-list option:selected').val(), { path: '/', domain: '.zodiac.nl'});
    location.reload();

  }

});

$('select.country-list').select2();


if (window.location.hash) {
    var hash = window.location.hash;
    
    if (window.location.hash.indexOf('NLerror') === 1) {
    
    var pairs = hash.split('=');
    var response = decodeURIComponent(pairs[1]).replace(/&language/g,'');
    $('#newsletterModal .alert').addClass('alert-danger');
    $('#newsletterModal .alert').text(response);
    $('#newsletterModal').modal();

    }
    else if (window.location.hash.indexOf('NLsucces') === 1) {
    $('#newsletterModal .alert').addClass('alert-success');
    $('#newsletterModal').modal();
    $('#newsletterModal .block-newsletter').hide();

    }

}

$('.fixed-bg').fadeIn(800);


// Hide Header on on scroll down
var didScroll;
var lastScrollTop = 0;
var delta = 30;
var navbarHeight = $('.banner').outerHeight();

$(window).scroll(function(event){
    didScroll = true;
});


function hasScrolled() {
  if ($(window).width() < 768) {
    var st = $(this).scrollTop();
    
    // Make sure they scroll more than delta
    if(Math.abs(lastScrollTop - st) <= delta) {
        return; }
    
    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > navbarHeight){
        // Scroll Down
        $('.banner').removeClass('nav-down').addClass('nav-up');
    } else {
        // Scroll Up
        if(st + $(window).height() < $(document).height()) {
            $('.banner').removeClass('nav-up').addClass('nav-down');
        }
    }
    
    lastScrollTop = st;
  }
}
setInterval(function() {
    if (didScroll) {
        hasScrolled();
        didScroll = false;
    }
}, 250);


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

